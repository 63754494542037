export class CheckDomain {
  domainName: string;
  password: string;
}
export class Phone {
  countryCode: number
  dialCode: string
  e164Number: string
  internationalNumber: string
  nationalNumber: string
  number: string
}
export class SignUp {
  phone: Phone = new Phone();name: any;
 ;
  email: string;
  countryCode: any;
  company: string;
  password: string;
}

export class ResignUp {
  name: string;
  tempUserId: string;
  password: string;
}

export class VerifyEmail {
  userId: string;
  token: string;
}

export class ReVerifyEmail {
  email: string;
}

export class Redirect {
  url: string;
}
