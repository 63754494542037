import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastrManager } from 'ng6-toastr-notifications';
import { HttpmethodsService } from 'src/app/service/httpmethods.service';

@Component({
  selector: 'app-forgot-passowrd',
  templateUrl: './forgot-passowrd.component.html',
  styleUrls: ['./forgot-passowrd.component.css']
})
export class ForgotPassowrdComponent implements OnInit {


  emailForgetPassword = new FormControl("", [Validators.required, Validators.email])
  forgetPasswordUrl: string = '/user/forgetUrl';


  constructor(
    private httpServices: HttpmethodsService,
    public toastr: ToastrManager,
    private dialogRef: MatDialogRef<any>,

  ) { }

  ngOnInit() {
  }



  
  forgetPassword() {

    if (this.emailForgetPassword.valid) {
      
          let obj = {
            email: this.emailForgetPassword.value
          }
      
          this.httpServices.postRequest(this.forgetPasswordUrl, obj).subscribe((res: any) => {
      
            
            this.emailForgetPassword.setValue('')

            this.toastr.successToastr('We have successfully sent you an email with the link.');
          // this.toastr.successToastr(res.message);

          this.close(res)
        }, 
        (err) => {
          console.log(err.error.message)
          this.toastr.successToastr('We have successfully sent you an email with the link.');
          this.close()

          }
        
        )
      
    }
  }

  close(res?) {
    if (res) {
      
      this.dialogRef.close(res)
    } else {

      this.dialogRef.close()
    }
  }

}
