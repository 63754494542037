import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrManager } from "ng6-toastr-notifications";
import {
  CheckDomain,
  ResignUp,
  ReVerifyEmail,
  VerifyEmail,
} from "src/app/AllModel/AllModel";
import { HttpmethodsService } from "src/app/service/httpmethods.service";

@Component({
  selector: "app-step3-form",
  templateUrl: "./step3-form.component.html",
  styleUrls: ["./step3-form.component.css"],
})
export class Step3FormComponent implements OnInit {
  companyUrl;
  checkDomainURL: string = "/user/check-subdomain";
  signUpURL: string = "/user/signup";
  getUser: string = "/user/subdomain-present-check";
  CheckDomainModel = new CheckDomain();
  ResignUpModel = new ResignUp();
  VerifyEmailModel = new VerifyEmail();
  ReVerifyEmailModel = new ReVerifyEmail();
  isDisabled = false;
  basicForm: FormGroup;
  company: any;
  name: any;
  email:any;
  _id: any;
  maxLenCheck:boolean = false

  existingDomain:boolean = false

  constructor(
    private formBuilder: FormBuilder,
    public httpServices: HttpmethodsService,
    public toastr: ToastrManager,
    public router: Router
  ) {
    this.company = sessionStorage.getItem("company");
    console.log(this.company)
    this._id = sessionStorage.getItem("id");
    this.email = sessionStorage.getItem("email");
    this.basicForm = this.formBuilder.group({
      domainName: new FormControl("", [Validators.required]),
    });
  }

  ngOnInit() {

    this.checkDomainConfig();
    if (this.basicForm) {
      if (this.company) {
        this.basicForm.updateValueAndValidity();
        this.CheckDomainModel.domainName = this.company.replace(/\s/g, '').trim().substring(0, 15);
      }
    }
  }

  CheckDomain() {
    if (this.basicForm.invalid) {
      this.toastr.errorToastr("All Fields are required");
      this.basicForm.markAllAsTouched();
      return false;
    }
    this.httpServices
      .getRequest(this.checkDomainURL + "/" + this.CheckDomainModel.domainName)
      .subscribe(
        (data: any) => {
          console.log("data",data);
          this.existingDomain = true
          // if (data.code == 400) {
          //   this.basicForm
          //     .get("domainName")
          //     .setValue(this.CheckDomainModel.domainName);
          //   // this.SignUp();
          // } else {
          //   this.toastr.infoToastr(data.message);
          //   this.router.navigateByUrl("basicDetails");

          // }
        },
        (error: any) => {
          console.log(error);
          this.existingDomain = false
          let err = error.error;
          if (err.code == 400) {
            this.basicForm
              .get("domainName")
              .setValue(this.CheckDomainModel.domainName);
            this.SignUp();
          } else {
            this.toastr.infoToastr(err.message);
          }
        }
      );
  }


  validateInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    const validValue = input.value.replace(/[^a-z]/g, ''); // Replace any non-lowercase letters
    input.value = validValue;
    
    this.CheckDomainModel.domainName = validValue
    if(this.CheckDomainModel.domainName.length > 15){
      this.maxLenCheck = true
      return
    }else{
      this.maxLenCheck = false
    }
  }

  SignUp() {
    if (this.basicForm.invalid) {
      this.toastr.errorToastr("All Fields are required");
      this.basicForm.markAllAsTouched();
      return false;
    }

    if (this._id && this.company) {
      this.ResignUpModel.tempUserId = this._id;
      this.ResignUpModel.name = this.CheckDomainModel.domainName;
      this.isDisabled = true;
      this.httpServices
        .postRequest(this.signUpURL, this.ResignUpModel)
        .subscribe(
          (data: any) => {
            if (data.message === "Signup Successfull") {
              this.isDisabled = false;
              sessionStorage.setItem(
                "domainName",
                this.CheckDomainModel.domainName
              );

              this.toastr.successToastr(data.message);
              this.router.navigateByUrl("basicDetails/" + data.data._id);
            }
          },
          (error: any) => {
            this.isDisabled = false;
            let err = error.error;
            if (err.code == 200) {
              this.toastr.successToastr(err.message);
            } else {
              this.toastr.errorToastr(err.message);
            }
          }
        );
    }
  }

  checkDomainConfig(){

    this.httpServices
      .postRequest(this.getUser ,{email:this.email})
      .subscribe(
        (data: any) => {
          if (data.message == "Domain Already Configured. Please Fill the Details") {
            this.router.navigateByUrl("basicDetails/"+data._id);
          } 
        },
        (error: any) => {
          let err = error.error;
          this.toastr.infoToastr(err.message);
        }
      );
    
  }


  

  entered(event) {
    if (event.key === ' ') {
      event.preventDefault();
    }
  }




}
