import { Component, Input, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
// import { log } from "console";
import { ToastrManager } from "ng6-toastr-notifications";
import { CountryISO, SearchCountryField } from "ngx-intl-tel-input";

import { SignUp } from "src/app/AllModel/AllModel";
import { HttpmethodsService } from "src/app/service/httpmethods.service";

@Component({
  selector: "app-step1-form",
  templateUrl: "./step1-form.component.html",
  styleUrls: ["./step1-form.component.css"],
})
export class Step1FormComponent implements OnInit {
  signUpform: FormGroup;
  signUpModel = new SignUp();
  signUpURL: string = "/tempuser/signup";
  conditionFlag: Boolean = true;
  showErr = false;
  isDisabled = false;

  SearchCountryField = SearchCountryField;
  // TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [
    CountryISO.India,
    CountryISO.Australia,
    CountryISO.Canada,
    CountryISO.UnitedArabEmirates,
    CountryISO.UnitedKingdom,
    CountryISO.UnitedStates,
  ];

  checkExistingUserURL: string = "/tempuser/check-email";
  isEmailExisting: boolean = false;
  checkDomainURL: string = "/tempuser/check-company";
  isCompanyExisting: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private httpServices: HttpmethodsService,
    public toastr: ToastrManager,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.signUpform = this.formBuilder.group({
      phone: new FormControl("", [
        Validators.required,
        Validators.maxLength(15),
        Validators.minLength(10),
      ]),
      email: new FormControl("", [Validators.required, Validators.email]),
      password: new FormControl("", [Validators.required]),
      companyName: new FormControl("", [
        Validators.required,
        Validators.maxLength(50),
      ]),
      // emailForgetPassword: new FormControl("", [Validators.required, Validators.email]),
    });
  }
  onChange(event) {
    this.conditionFlag = event.checked;
    if (this.conditionFlag) {
      this.showErr = false;
    } else {
      this.showErr = true;
    }
  }

  validateInput(event: Event): void {
    const input = event.target as HTMLInputElement;

    // Allow only letters and spaces, remove other characters
    const filteredValue = input.value.replace(/[^a-zA-Z ]/g, "");

    // Update the input value if it contains invalid characters
    if (input.value !== filteredValue) {
      input.value = filteredValue;
    }

    // Update the model
    this.signUpModel.company = filteredValue;
  }

  entered(event) {
    if (event.key === " ") {
      event.preventDefault();
    }
  }

  SignUp() {
    if (this.signUpform.invalid) {
      // console.log(this.signUpform);

      this.toastr.errorToastr("All Fields are required");
      if (this.conditionFlag == false) {
        this.showErr = true;
      }
      this.signUpform.markAllAsTouched();
      return false;
    }
    if (this.conditionFlag == false) {
      // console.log(this.signUpform);

      this.toastr.errorToastr("All Fields are required");
      this.showErr = true;
      return false;
    }

    // console.log(this.signUpform);
    this.signUpModel.company = this.signUpform.value.companyName; // Assuming companyName is used as the name
    this.signUpModel.email = this.signUpform.value.email;
    this.signUpModel.countryCode = this.signUpform.value.phone.dialCode;
    this.signUpModel.phone = this.signUpform.value.phone.number; // Assuming phone is an object and .number is its property
    this.signUpModel.password = this.signUpform.value.password;

    const payload = {
      company: this.signUpModel.company,
      email: this.signUpModel.email,
      phone: this.signUpModel.phone,
      password: this.signUpModel.password,
    };

    // console.log(payload)
    if (
      payload.phone != null &&
      payload.company != null &&
      payload.email != null &&
      payload.password != null
    ) {
      this.isDisabled = true;
      this.httpServices.postRequest(this.signUpURL, this.signUpModel).subscribe(
        (data: any) => {
          this.isDisabled = false;
          sessionStorage.setItem("id", data._id);
          sessionStorage.setItem("email", data.email);
          sessionStorage.setItem("company", data.company);
          this.httpServices.isStepCompleted2 = true;
          this.httpServices.stepValue = 2;
        },
        (error: any) => {
          this.isDisabled = false;
          let err = error.error;
          this.toastr.errorToastr(err.message);

          if (err.message == "Email Already Exists") {
            this.isDisabled = false;
            sessionStorage.setItem("id", err._id);
            sessionStorage.setItem("email", err.email);
            sessionStorage.setItem("company", err.company);
            this.httpServices.isStepCompleted2 = true;
            this.httpServices.stepValue = 2;
          }
        }
      );
    } else {
      // console.log(this.signUpform);

      this.toastr.errorToastr("All Fields are required");
    }
  }

  checkExistingEmail() {
    if (this.signUpModel.email.length > 6) {
      this.httpServices
        .postRequest(this.checkExistingUserURL, {
          email: this.signUpModel.email,
        })
        .subscribe({
          next: (val: any) => {
            // console.log("existing check", val?.existing)
            this.isEmailExisting = val?.existing;
          },
          error: (err) => {
            console.log(err);
          },
        });
    }
  }

  checkExistingCompany() {
    this.httpServices
      .postRequest(this.checkDomainURL, { company: this.signUpModel.company })
      .subscribe(
        (data: any) => {
          // console.log( "data", data);

          if (data?.existing) {
            this.isCompanyExisting = true;
          } else {
            this.isCompanyExisting = false;
          }
        },
        (error: any) => {
          let err = error.error;
          console.log(error);
        }
      );
  }
}
